(function () {
    var toggle = document.querySelector('.header-toggle');
    if (toggle) {
        toggle.addEventListener('click', function () {

            this.classList.toggle('open');
            $('.header').stop().slideToggle();

        });
    }

})();
